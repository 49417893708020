header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 40px 50px 100px 50px;
  color: #ddd;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.23) 33%,
    rgba(0, 0, 0, 0.66) 100%
  );
  opacity: 1;
  line-height: 10px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

#time-display {
}

#date-display {
  margin-bottom: 0;
}
