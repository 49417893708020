.Background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 1;
  transition: background-image 1s ease-in-out;
}
