footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 50px;
  color: #b8b8b8;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.33) 33%,
    rgba(0, 0, 0, 0.66) 100%
  );
  opacity: 1;
  line-height: 10px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}
.sep {
  border-top: 1px solid white;
  margin-top: 20px;
  padding-top: 25px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}

#credits {
  color: #eee;
}
#refresh-btn,
#burst-link {
  color: white;
  font-size: 13px;
  cursor: pointer;
  color: #ccc;
  transition: opacity 0.2s ease-in-out;
}

#refresh-btn.disabled {
  opacity: 0.4;
  cursor: alias;
}

#credits h1 {
  text-align: right;
  margin-bottom: 14px;
}

#credits #location{
  font-size: 80%;
  margin-left: 7px;
  margin-bottom: 3px;
}

#credits #photographer, {
  margin-bottom: 3px;
}

#credits p {
  text-align: right;
}

#credits p a {
  color: white;
  text-decoration: underline;
}
#credits {
  min-width: 300px;
}

.stock {
  margin-bottom: 0;
  position: relative;
}

.remove-stock {
  position: absolute;
  left: -27px;
  padding: 5px;
  bottom: 0px;
  font-size: 80%;
  opacity: 0;
  transition: opacity 0.2s;
  color: white;
}
.remove-stock:hover {
  opacity: 1;
  cursor: pointer;
}
.save-stock {
  position: absolute;
  left: -27px;
  padding: 5px;
  bottom: 0px;
  font-size: 80%;
  color: white;
}
.save-stock:hover {
  cursor: pointer;
}
#stock-list {
  position: relative;
}
.input {
  border: 1px solid black;
  padding: 5px;
  border-radius: 3px;
  font-size: 15px;
  width: 300px;
}

.error-icon {
  top: 6px;
  position: absolute;
  font-size: 80%;
}

.hide {
  opacity: 0;
}
